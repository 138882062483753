import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useTimer } from "../../Hooks/useTimer";
import { useFormikContext } from "formik";
import { initialvalues } from "../FormikWrapper/RegisterFormikWrapper";
import "./StepperOne.scss";
import Icon from "@mdi/react";
import { mdiInformationOutline } from "@mdi/js";
import { CustomLoader } from "../Loader/Loader";

interface OTPConfirmationProps {
  onResendOTP: (email: string) => void;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  Page?: number;
}

export const OTPConfirmation: React.FC<OTPConfirmationProps> = ({
  onResendOTP,
  setPage,
}) => {
  const { values, setFieldValue, isSubmitting } =
    useFormikContext<typeof initialvalues>();
  const RESEND_INTERVAL_S = 0;
  const [disableResendOtp, setDisableResentOtp] = useState(true);
  const { time, reStartTimer } = useTimer();

  useEffect(() => {
    if (disableResendOtp) {
      reStartTimer();
    }
  }, [disableResendOtp]);

  useEffect(() => {
    if (time === RESEND_INTERVAL_S) {
      setDisableResentOtp(false);
    }
  }, [time]);
  return (
    <div className="otp-confirmation-popup">
      <div className="outer-box">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-column justify-content-center">
              <div className="alert-section">
                <div>
                  <Icon
                    className="mdi-alert"
                    path={mdiInformationOutline}
                  ></Icon>
                </div>
                <div>
                  <p className="withdraw-bid">
                    The OTP is valid for 3 minutes. If the OTP is invalid or has
                    expired, please request a new one.
                  </p>
                </div>
              </div>
              <p className="text-secondary mb-4">
                We've sent OTP to <b style={{ color: 'black' }}>{values?.email}</b>. Please check your
                email and enter OTP below  </p>

              <OTPInput
                inputStyle="form-control otp-input-custom mb-2"
                inputType="tel"
                value={values.otp}
                onChange={(value) => setFieldValue("otp", value)}
                numInputs={4}
                renderSeparator={<span>&nbsp;&nbsp;</span>}
                renderInput={(props) => <input {...props} />}
              />
              <p className="mb-4 small">
                हामीले OTP <b style={{ color: 'black' }}>{values?.email}</b> मा पठायौं। कृपया तपाईंको इमेल जाँच गर्नुहोस् र OTP प्रविष्ट गर्नुहोस्
              </p>
              {disableResendOtp ? (
                <span className="text-secondary">
                  You can resend otp after {time} sec
                </span>
              ) : (
                <span
                  role="button"
                  className="text-secondary"
                  onClick={() => {
                    onResendOTP?.(values?.email);
                    setDisableResentOtp(true);
                  }}
                >
                  <u>Resend OTP</u>
                </span>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <hr />
          </div>
          <div className="col-lg-12">
            <div className="line-section secondary-section">
              <button className="btn primary-btn" onClick={() => setPage?.(1)}>
                Back
              </button>
            </div>
            <div className="line-section primary-section">
              <button
                className="btn primary-btn"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CustomLoader
                    type="TailSpin"
                    color="#FFFFFF"
                    height={20}
                    width={48}
                  />
                ) : (
                  <span>Submit</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
