import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BodyDataBidder, getBidderDetail } from "../Api/GetApi";
import BidDocumentBoq from "../BidDocumentBoq/BidDocumentBoq";
import { useAuth } from "../Context/AuthContext";
import { ConfirmationPopUp, ConfirmMessage } from "../PopUp/ConfirmationPopUp";
import "./BidderDetails.scss";
import Modal from "react-modal";
import { PostTechnicalPass, postTenderAward } from "../Api/PostApi";
import {
  NewRejectPopup,
  TenderRejectInitial,
} from "../RejectPopup/NewRejectPopup";
import { toast } from "react-toastify";
import {
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiCardBulletedOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import { RejectTender } from "../Api/PutApi";
import { catchError } from "../utils/catchError";
import { DocumentResponse } from "../EditBids/EditBids";
import {
  decryptData,
  isNoticeCategoryStandingList,
  isPublisher,
} from "../../utils/services";
import {
  TechnicalPass,
  TechnicalPassMessage,
} from "../TechnicalPass/TechnicalPass";
import { MyBidLoader } from "../MyBidLoader/MyBidLoader";
import { NotFound } from "../NotFound/NotFound";
import { CompanyDetails } from "./CompanyDetails";
import { AdditionalAttributes } from "../FormikWrapper/TenderFormikWrapper";
import { ResponseBoq } from "../utils/interface";
import { BidCategory } from "../../utils/EnumConstants";
export interface OrganizationDetail {
  company_name?: string;
  organization_category?: string;
  office_email?: string;
  website_url?: string;
  company_contact_no: string;
  province: string;
  district: string;
  municipality: string;
}

export interface TenderNoticeDetail {
  category: string;
  opening_date: any;
  id: number;
  notes: string;
  terms: string;
  title: string;
  additional_attributes: AdditionalAttributes[];
  document_needed?: string;
  notice_category: string;
  is_main: Boolean;
  boq_catalog_name?: string;
  boq_financial_documents?: string;
  optional_documents?: string;
}

export const BidderDetail = () => {
  const [boq, setBoq] = useState<ResponseBoq | undefined>();
  const [passLoader, setPassLoader] = useState(false);
  const [failLoader, setFailLoader] = useState(false);
  const [awardNote, setAwardNote] = useState("");
  const [tenderNoticeData, setTenderNoticeData] =
    useState<TenderNoticeDetail>();

  const [documentData, setDocumentData] = useState<
    DocumentResponse | undefined
  >();
  const [organizationDetail, setOrganizationDetail] =
    useState<OrganizationDetail>();
  const [loading, setLoading] = useState(true);

  const { bidder_id, id } = useParams();

  const bodyId = {
    bidder_id: bidder_id,
    tender_notice_id: id,
    awarded_reason: awardNote,
  };

  const navigate = useNavigate();

  const [isApproveOpen, setIsApproveOpen] = useState(false);
  const [isRejectOpen, setIsRejectOpen] = useState(false);
  const [isTechnicalOpen, setIsTechnicalOpen] = useState(false);
  const [isNotAwardedDialog, setIsNotAwardedDialog] = useState(false);
  const { role } = useAuth();

  const toggleRejectModal = () => {
    setIsRejectOpen(!isRejectOpen);
  };

  const toggleTechnicalModal = () => {
    setIsTechnicalOpen(!isTechnicalOpen);
  };
  const toggleApproveModal = () => {
    setIsApproveOpen(!isApproveOpen);
  };

  const popUpContents: ConfirmMessage = {
    question1: "Award Bid!",
    question2: "Are you sure you want to Award bid?",
    warn: "An award email will be sent to the chosen bidder",
    cancelBtn: "Cancel Award",
    aprroveBtn: "Yes, Award bid",
  };

  const technicalPass: TechnicalPassMessage = {
    question1: "Technical Proposal!",
    question2: "Please select the Techincal Status of the Bid below.",
    // warn: "Once you pass or fail the technical assessment, you can't change technical status again",
    cancelBtn: "Cancel Technical Pass",
    aprroveBtn: "Pass",
    disapproveBtn: "Fail",
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height: "auto",
      width: "600px",
    },
  };
  //Function to hit award api
  const TenderAward = () => {
    postTenderAward(bodyId)
      .then((res: any) => {
        toast.success(res.data.status.status_message);
        toggleApproveModal();
        fetchBidderDetail(bodyId);
      })
      .catch((err) => {
        toggleApproveModal();
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };

  //Function to hit rejection api
  const TenderRejection = (data: TenderRejectInitial) => {
    RejectTender(data)
      .then((res) => {
        toast.success(res.data.status.status_message);
        fetchBidderDetail(bodyId);
        toggleRejectModal();
      })
      .catch((err) => {
        toggleRejectModal();
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };

  const handleTechnicalPass = (value: boolean, note: string) => {
    if (value) {
      setPassLoader(true);
    } else {
      setFailLoader(true);
    }
    const data = {
      bidder_id: bidder_id,
      tender_notice_id: id,
      technical_note: note,
      status: value,
    };
    PostTechnicalPass(data)
      .then((res) => {
        toast.success(res.data.status.status_message);
        navigate(`/bids/view/${bodyId?.tender_notice_id}`);
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        toggleTechnicalModal();
        if (value) {
          setPassLoader(false);
        } else {
          setFailLoader(false);
        }
      });
  };

  //Function for bidder fectching logic
  const fetchBidderDetail = (data: BodyDataBidder) => {
    getBidderDetail(data)
      .then((res) => {
        const responseData = res.data.data;
        setDocumentData(responseData.documents);
        setOrganizationDetail(responseData.organization_detail);
        setTenderNoticeData(responseData.tender_notice);
        const bidBoq = JSON.parse(
          decryptData(responseData.tender_notice.bid_boq)
        );

        setBoq(bidBoq);
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchBidderDetail(bodyId);
  }, []);

  const showTechnicalBtn =
    (isNoticeCategoryStandingList(tenderNoticeData?.notice_category ?? "") &&
      tenderNoticeData?.is_main) ||
    (tenderNoticeData?.category === BidCategory.DOUBLE_ENVELOPE &&
      tenderNoticeData?.opening_date === null);

  let showAwardDisqualifyBtn = false;
  if (isNoticeCategoryStandingList(tenderNoticeData?.notice_category ?? "")) {
    showAwardDisqualifyBtn = !tenderNoticeData?.is_main;
  } else {
    showAwardDisqualifyBtn =
      tenderNoticeData?.category === BidCategory.SINGLE_ENVELOPE ||
      tenderNoticeData?.opening_date;
  }

  return (
    <>
      {loading ? (
        <div className="spinner">
          <MyBidLoader />
        </div>
      ) : (
        <>
          <Modal
            isOpen={isRejectOpen}
            onRequestClose={toggleRejectModal}
            contentLabel="My dialog"
            style={customStyles}
            ariaHideApp={false}
          >
            <NewRejectPopup
              toggleModal={toggleRejectModal}
              onReject={(data: TenderRejectInitial) => {
                TenderRejection(data);
              }}
              bidder_id={bodyId.bidder_id}
              tender_notice_id={bodyId.tender_notice_id}
              isNotAwardedDialog={isNotAwardedDialog}
            />
          </Modal>
          <Modal
            isOpen={isTechnicalOpen}
            onRequestClose={toggleTechnicalModal}
            contentLabel="My dialog"
            style={customStyles}
            ariaHideApp={false}
          >
            <TechnicalPass
              popUpContents={technicalPass}
              toggleModal={toggleTechnicalModal}
              passLoader={passLoader}
              failLoader={failLoader}
              submitTechnicalStatus={(value: any, note: string) => {
                handleTechnicalPass(value, note);
              }}
            />
          </Modal>
          <Modal
            isOpen={isApproveOpen}
            onRequestClose={toggleApproveModal}
            contentLabel="My dialog"
            style={customStyles}
            ariaHideApp={false}
          >
            <ConfirmationPopUp
              popUpContents={popUpContents}
              toggleModal={toggleApproveModal}
              awardNote={awardNote}
              setAwardNote={setAwardNote}
              wantToWriteNote={true}
              onAprroveButton={() => {
                TenderAward();
              }}
            />
          </Modal>
          <div className="bidder-details">
            <div className="detail-wrapper">
              <div className="container">
                {organizationDetail && documentData ? (
                  <>
                    {isPublisher(role) && (
                      <>
                        <div className="back-button mb-3">
                          {/* <Link to={`/bids/view/${bodyId?.tender_notice_id}`}>
                            <Icon className="mdi-back" path={mdiArrowLeft} />
                            <b>Bidder List</b>
                          </Link> */}
                        </div>
                        <div className="card details-section">
                          <div className="padd-16">
                            <div className="card-wrapper">
                              <div className="headingButtons">
                                <div className="bid-before">
                                  <div className="tag-info">
                                    <p className="bid-details">Bidder Detail</p>
                                  </div>
                                </div>
                                <div className="d-flex align-center">
                                  {/* <ShareButton shareUrl={shareUrl} size={24} /> */}
                                  <div className="border-line"></div>
                                  {isPublisher(role) && (
                                    <>
                                      <div className="btn-show">
                                        {showTechnicalBtn && (
                                          <button
                                            className="btn btn-secondary"
                                            onClick={() => {
                                              toggleTechnicalModal();
                                            }}
                                          >
                                            <Icon
                                              className=" mdi-close mdi-alert"
                                              path={mdiCardBulletedOutline}
                                            />
                                            Technical Evaluation
                                          </button>
                                        )}

                                        {showAwardDisqualifyBtn && (
                                          <>
                                            <button
                                              className="btn reject"
                                              onClick={() => {
                                                setIsNotAwardedDialog(false);
                                                toggleRejectModal();
                                              }}
                                            >
                                              <Icon
                                                className="mdi-close mdi-alert"
                                                path={mdiCloseCircleOutline}
                                              />
                                              Disqualify
                                            </button>
                                            <button
                                              className="btn approve mr-2"
                                              onClick={() => {
                                                toggleApproveModal();
                                              }}
                                            >
                                              <Icon
                                                className="mdi-alert blue-icon"
                                                path={mdiCheckCircleOutline}
                                              />
                                              Award
                                            </button>
                                            <button
                                              className="btn not-award "
                                              onClick={() => {
                                                setIsNotAwardedDialog(true);
                                                toggleRejectModal();
                                              }}
                                            >
                                              <Icon
                                                className="mdi-alert blue-icon"
                                                path={mdiCloseCircleOutline}
                                              />
                                              Not Awarded
                                            </button>
                                          </>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                              <CompanyDetails
                                organizationDetail={organizationDetail}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {documentData && (
                      <BidDocumentBoq
                        organizationDetail={
                          organizationDetail as OrganizationDetail
                        }
                        boq={boq}
                        documentData={documentData}
                        tenderNoticeData={tenderNoticeData}
                      />
                    )}
                  </>
                ) : (
                  <div>
                    <NotFound message="Something Went Wrong" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default BidderDetail;
