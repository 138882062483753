import React, { useEffect, useState } from "react";
import "./BOQ.scss";
import { Props } from "../JointVenture/JointVenture";
import { DocumentProps } from "../Document/Document";
import { useNavigate, useParams } from "react-router-dom";
import "../pdfDropzone/pdfDropzone.scss";
import { CustomLoader } from "../Loader/Loader";
import { DetailBid } from "../BidDetails/BidDetails";
import * as Yup from "yup";
import {
  concateToasterError,
  convertToFormData,
  deepClone,
  encryptData,
} from "../../utils/services";
import { BidDetailTabs } from "../BidDetailStepper/BidDetailStepper";
import {
  BidDocumentInitialValues,
  IBidBoq,
  useBidFormikContext,
} from "../FormikWrapper/BidFornikWrapper";
import { completeBidSchema } from "../FormikWrapper/BidalidationSchemas";
import { Field, FormikErrors } from "formik";
import { postBidSubmission } from "../Api/PostApi";
import { toast } from "react-toastify";
import { catch500Error } from "../utils/catchError";
import Modal from "react-modal";
import { ConfirmationPopUp } from "../PopUp/ConfirmationPopUp";
import { nepaliAmountFormat } from "nepali-number";
import { DropZoneBox } from "../pdfDropzone/pdfDropzone";
import { IBoqCatalogueFiles } from "../EditBids/EditBids";
import {
  ResponseBoq,
  ResponseBoqItem,
  ResponseBoqObject,
} from "../utils/interface";
import { UploadedFile } from "../UploadedFile/UploadedFile";
import { DocumentType, TenderAwardType } from "../../utils/EnumConstants";
import Icon from "@mdi/react";
import { mdiTrashCan } from "@mdi/js";

export interface BoqProps extends Props {
  boq?: ResponseBoq;
}
export interface BOQProps extends DocumentProps {
  boq?: ResponseBoq;
  BoqPost?: Function;
  bidDetail: DetailBid;
  boqFinancialResDocs?: IBoqCatalogueFiles;
  reFetchData?: () => void;
}

const BOQValidateSchema = Yup.array().of(
  Yup.object({
    item: Yup.array().of(
      Yup.object({
        rate: Yup.number()
          .test("is-valid", "Invalid Rate!", (value: any) => {
            if (!/^[1-9]+(\.[0-9]{1,2})?$/.test(value)) {
              return false; // Invalid
            }
            return true;
          })
          .typeError("Invalid Rate!")
          .nullable(),
      })
    ),
  })
);

export type ValidationError = {
  [key: string]: string;
};

type PopUpType = "Confirmation" | "Delete Category" | "Delete Item" | "";

const confirmationPopupContent = {
  question1: "Are you sure want to submit?",
  warn: "Note: Your company documents will be auto forwarded from your profile to publisher for bidding process. Make sure, you have uploaded correct and upto date documents",
  aprroveBtn: "Submit",
  cancelBtn: "Cancel",
};

const deletePopupContent = {
  question1: "Are you sure want to remove?",
  warn: "Note: If you remove this, you won't be able to submit the BOQ for this category.",
  aprroveBtn: "Yes, remove",
  cancelBtn: "Cancel",
};

export const BOQ: React.FC<BOQProps> = ({
  setPage,
  resDocument,
  bidDetail,
  documentNeed,
  catalogueFiles,
  boqFinancialFiles,
  boqFinancialResDocs,
  reFetchData,
  boq,
}) => {
  const {
    values,
    errors,
    setFieldError,
    setFieldValue,
    isSubmitting,
    setFieldTouched,
  } = useBidFormikContext();
  const [showPopupType, setShowPopupType] = useState<PopUpType>();
  const [isOpen, setIsOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showFullNotes, setShowFullNotes] = useState(false);
  const [showFullTerms, setShowFullTerms] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedRow, setSelectedRow] = useState<string | number>("");

  const { id } = useParams();
  const navigate = useNavigate();

  function toggleModal() {
    setIsOpen(!isOpen);
  }
  function isRateFilled(boqDetails: IBidBoq) {
    for (const category of boqDetails.boq) {
      for (const item of category.item) {
        if (item.rate === "") {
          return true;
        }
      }
    }
    return false;
  }

  const bidSubmision = (data: BidDocumentInitialValues) => {
    const obj = {
      ...data.jointVenture,
      ...data.documents,
      ...data.bidFee,
      ...data?.boq_details?.boqFinancialDoc,
      bid_boq: encryptData(JSON.stringify(data.boq_details?.boqData)),
    };

    const formdata = convertToFormData(obj);
    setSubmitLoading(true);
    if (isRateFilled(data.boq_details?.boqData)) {
      return;
    }
    postBidSubmission(formdata, data.documents.tender_notice_id)
      .then((res) => {
        toast.success("Bid submitted");
        navigate("/my-ebids");
      })
      .catch((err) => {
        catch500Error(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => setSubmitLoading(false));
  };

  const editField = (
    fieldName: string,
    value: string,
    upperIndex: number,
    lowerIndex: number
  ) => {
    const cloned = deepClone(values.boq_details.boqData.boq);
    cloned[upperIndex].item[lowerIndex][fieldName] = value;
    OnChangeValidation(cloned);
    const amountCalculatedArray = CalculateAmount(cloned);
    const overallBoqTotals = calculateTotals(amountCalculatedArray);
    setFieldValue("boq_details.boqData.boq", amountCalculatedArray);
    setFieldValue("boq_details.grand_total", overallBoqTotals.grandTotal);
  };

  const CalculateAmount = (cloned: ResponseBoqObject[]) => {
    const newCloned = deepClone(cloned);
    let amountCalculatedArray: ResponseBoqObject[] = [];
    newCloned.forEach((element: ResponseBoqObject) => {
      let newObject = { ...element };
      if (element.item.length > 0) {
        const updatedAmount = element.item.map(
          (innerItems: ResponseBoqItem) => {
            return {
              ...innerItems,
              amount: (
                Number(innerItems.quantity) * Number(innerItems.rate)
              ).toFixed(2),
            };
          }
        );
        newObject.item = updatedAmount;
      }
      amountCalculatedArray.push(newObject);
    });

    return amountCalculatedArray;
  };

  const OnChangeValidation = (boqData: ResponseBoqObject) => {
    BOQValidateSchema.validate(boqData, { abortEarly: false })
      .then(() => {})
      .catch((errors) => {
        const fieldErrors: Record<string, string> = {};
        errors.inner.forEach((error: any) => {
          const fieldPath = error.path;
          fieldErrors[fieldPath] = error.message;
          setFieldError(fieldPath, error.message);
          setFieldTouched(fieldPath, true);
        });
      });
  };

  const onHandleSubmit = (
    values: BidDocumentInitialValues,
    documentNeed: string,
    catalogueFiles: string,
    boqFinancialFiles: string,
    boqFinancialResDocs: IBoqCatalogueFiles
  ) => {
    completeBidSchema(
      documentNeed as string,
      catalogueFiles as string,
      bidDetail.additional_attributes.map((each) => each.column),
      boqFinancialFiles as string,
      boqFinancialResDocs as IBoqCatalogueFiles,
      resDocument?.fee.e_payment as string
    )
      .validate(values, { abortEarly: false })
      .then(() => {
        setShowPopupType("Confirmation");
        toggleModal();
      })
      .catch((error) => {
        const fieldErrors: any = {};
        error.inner.forEach((error: any) => {
          setFieldError(error.path, error.message);
          fieldErrors[error.path] = error.message;
        });

        //fieldErrors Object would appeaer as {'jointVenture.boq':something} so we have to jsut extract the jointVenture from that object to show in error
        const errors = concateToasterError(fieldErrors);
        toast.error(errors);
      });
  };

  const calculateTotals = (boqData: ResponseBoqObject[]) => {
    const boqTotal =
      boqData &&
      boqData.reduce((prev: any, current: any) => {
        return (
          prev +
          current["item"].reduce((p: any, c: any) => {
            return p + c.quantity * c.rate;
          }, 0)
        );
      }, 0);
    const vatAmount = bidDetail.isVat ? 0.13 * boqTotal : 0;
    const grandTotal = boqTotal + vatAmount;
    return {
      boqTotal: boqTotal,
      vatAmount: vatAmount,
      grandTotal: grandTotal,
    };
  };

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
    },
  };

  const toggleNotes = () => {
    setShowFullNotes(!showFullNotes);
  };

  const toggleTerms = () => {
    setShowFullTerms(!showFullTerms);
  };

  const checkStringExceed = (value: string) => {
    if (value.length > 100) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (resDocument) {
      const completeResDocument = {
        ...resDocument.fee,
      };
      setFieldValue("bidFee.filledDocuments", completeResDocument);
    }
  }, [resDocument]);

  const handleDeleteCategory = (category: string) => {
    const updatedBoq = values.boq_details.boqData.boq.filter((cat) => {
      return cat.category !== category;
    });

    setFieldValue("boq_details.boqData.boq", updatedBoq);
    toast.success("Deleted successfully!");
    setShowPopupType("");
    toggleModal();
  };

  const handleDeleteItem = (category: string, itemId: string | number) => {
    const updatedBoq = values.boq_details.boqData.boq.map((cat) => {
      if (cat.category === category) {
        return {
          ...cat,
          item: cat.item.filter((item) => item.id !== itemId),
        };
      }
      return cat;
    });

    setFieldValue("boq_details.boqData.boq", updatedBoq);
    toast.success("Deleted successfully!");
    setShowPopupType("");
    toggleModal();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setShowPopupType("");
          toggleModal();
        }}
        contentLabel="My dialog"
        style={customModalStyles}
        ariaHideApp={false}
      >
        <ConfirmationPopUp
          popUpContents={
            showPopupType === "Confirmation"
              ? confirmationPopupContent
              : deletePopupContent
          }
          toggleModal={() => {
            setShowPopupType("");
            toggleModal();
          }}
          onAprroveButton={() => {
            showPopupType === "Confirmation"
              ? bidSubmision(values)
              : showPopupType === "Delete Category"
              ? handleDeleteCategory(selectedCategory)
              : handleDeleteItem(selectedCategory, selectedRow);
          }}
          activeButtonLoading={submitLoading}
          acceptTerm={showPopupType === "Confirmation" ? true : false}
        />
      </Modal>
      <div className="boq-wrapper">
        <div className="boq-bill">
          <div className="padd-16">
            {boq?.boq_details && boq?.boq_details?.length > 0 && (
              <>
                <div className="scroll">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr className="table-header">
                        <th>Category</th>
                        <th>S.No.</th>
                        <th>Item/Work Description</th>
                        <th>Unit</th>

                        <th>Quantity</th>
                        <th>Rate(Currency)</th>
                        {bidDetail &&
                          bidDetail?.additional_attributes?.map(
                            (eachAtr, index) => (
                              <th key={index}>{eachAtr.column}</th>
                            )
                          )}
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.boq_details.boqData.boq?.map(
                        (r: ResponseBoqObject, catIndex: number) => {
                          return (
                            <React.Fragment key={catIndex}>
                              {r?.item.map(
                                (row: ResponseBoqItem, rowIndex: number) => {
                                  return (
                                    <>
                                      <tr key={rowIndex}>
                                        {rowIndex === 0 && (
                                          <td rowSpan={r["item"].length}>
                                            {r["category"]}
                                          </td>
                                        )}
                                        <td>{rowIndex + 1}</td>
                                        <td>{row?.item_description}</td>
                                        <td>{row?.unit}</td>

                                        <td>{row?.quantity}</td>
                                        <td>
                                          {values.boq_details.boqData.boq[
                                            catIndex
                                          ]["item"][rowIndex]["fixed"] ? (
                                            <p className="fixed-rate">
                                              {
                                                values.boq_details.boqData.boq[
                                                  catIndex
                                                ]["item"][rowIndex].rate
                                              }
                                            </p>
                                          ) : (
                                            <>
                                              <input
                                                disabled={
                                                  values.boq_details.boqData
                                                    .boq[catIndex]["item"][
                                                    rowIndex
                                                  ]["blank"]
                                                }
                                                placeholder="Enter rate"
                                                type="text"
                                                value={
                                                  values.boq_details.boqData
                                                    .boq[catIndex]["item"][
                                                    rowIndex
                                                  ].rate == "0"
                                                    ? ""
                                                    : values.boq_details.boqData
                                                        .boq[catIndex]["item"][
                                                        rowIndex
                                                      ].rate
                                                }
                                                onChange={(e) => {
                                                  editField(
                                                    "rate",
                                                    e.target.value,
                                                    catIndex,
                                                    rowIndex
                                                  );
                                                }}
                                              />
                                            </>
                                          )}

                                          <span className="error">
                                            {errors &&
                                              (
                                                (
                                                  errors?.boq_details?.boqData
                                                    ?.boq?.[
                                                    catIndex
                                                  ] as FormikErrors<ResponseBoqObject>
                                                )?.item?.[
                                                  rowIndex
                                                ] as FormikErrors<ResponseBoqItem>
                                              )?.rate}
                                          </span>
                                        </td>

                                        {bidDetail &&
                                          bidDetail?.additional_attributes
                                            .length > 0 &&
                                          bidDetail?.additional_attributes.map(
                                            (additionalCol, index) => (
                                              <td
                                                key={`${additionalCol.column}${index}`}
                                              >
                                                <Field
                                                  className="form-control mid-area"
                                                  value={
                                                    values.boq_details.boqData
                                                      .boq[catIndex].item[
                                                      rowIndex
                                                    ].additional_attributes[
                                                      additionalCol.column
                                                    ]
                                                  }
                                                  placeholder={`Enter ${additionalCol.column}`}
                                                  type="text"
                                                  onChange={(e: any) => {
                                                    setFieldValue(
                                                      `boq_details.boqData.boq[${catIndex}].item[${rowIndex}].additional_attributes[${additionalCol.column}]`,
                                                      e.target.value
                                                    );
                                                  }}
                                                />
                                                <span className="error">
                                                  {errors &&
                                                    (
                                                      (
                                                        errors?.boq_details
                                                          ?.boqData?.boq?.[
                                                          catIndex
                                                        ] as FormikErrors<ResponseBoqObject>
                                                      )?.item?.[
                                                        rowIndex
                                                      ] as FormikErrors<ResponseBoqItem>
                                                    )?.additional_attributes?.[
                                                      additionalCol.column
                                                    ]}
                                                </span>
                                              </td>
                                            )
                                          )}
                                        <td>{row?.amount}</td>
                                        {bidDetail?.award_type ===
                                          TenderAwardType.MULTIPLE_BIDDER && (
                                          <>
                                            {values.boq_details.boqData.boq
                                              ?.length > 1 &&
                                              rowIndex === 0 && (
                                                <div
                                                  className="icon-section"
                                                  onClick={() => {
                                                    setSelectedCategory(
                                                      r?.category
                                                    );
                                                    setShowPopupType(
                                                      "Delete Category"
                                                    );
                                                    toggleModal();
                                                  }}
                                                  title="Delete Category"
                                                >
                                                  <Icon
                                                    path={mdiTrashCan}
                                                    className="mdi-trash"
                                                  />
                                                </div>
                                              )}
                                          </>
                                        )}
                                      </tr>
                                    </>
                                  );
                                }
                              )}
                            </React.Fragment>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
                <table className="w-100 table-striped">
                  <tbody>
                    <tr>
                      <td>
                        <div className=" rate-flex">
                          <span className="total">BOQ Total</span>
                          <div className="amount-section">
                            <span className="rate-amount">
                              {nepaliAmountFormat(
                                calculateTotals(
                                  values.boq_details.boqData
                                    .boq as ResponseBoqObject[]
                                ).boqTotal
                              )}
                            </span>
                            <span className="inputfield-item">NPR</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className=" rate-flex">
                          <span className="total">
                            VAT <span> [13%]</span>
                          </span>
                          <div className="amount-section">
                            <span className="rate-amount">
                              {nepaliAmountFormat(
                                calculateTotals(
                                  values.boq_details.boqData
                                    .boq as ResponseBoqObject[]
                                ).vatAmount.toFixed(2)
                              )}
                            </span>
                            <span className="inputfield-item">NPR</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className=" rate-flex">
                          <span className="total">Grand Total </span>
                          <div className="amount-section">
                            <span className="rate-amount">
                              {nepaliAmountFormat(
                                calculateTotals(
                                  values.boq_details.boqData
                                    .boq as ResponseBoqObject[]
                                ).grandTotal.toFixed(2)
                              )}
                            </span>
                            <span className="inputfield-item"> NPR</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
            {Boolean(boqFinancialFiles) && (
              <div
                className={`${
                  boq?.boq_details && boq?.boq_details?.length > 0
                    ? "custom-border-margin"
                    : ""
                } boq-financial-block`}
              >
                {/* <h5 className="drop-title">BOQ Financial Documents</h5> */}
                {boqFinancialFiles?.split(",").map((item) => {
                  return (
                    <div>
                      <div>
                        <h5 className="drop-title">
                          {" "}
                          {item.replaceAll("_", " ")}
                        </h5>
                        {boqFinancialResDocs &&
                          boqFinancialResDocs?.[item]?.file_url && (
                            <UploadedFile
                              url={boqFinancialResDocs?.[item]?.file_url || ""}
                              tenderId={id}
                              documentType={DocumentType.FINANCIAL}
                              documentLabel={"file_url"}
                              deleteDocument={true}
                              reFetchData={reFetchData}
                            />
                          )}
                        <div className="upload-venture">
                          <DropZoneBox
                            name={`boq_details.boqFinancialDoc.${item}`}
                            placeholder="Drag file here or Choose files from your device"
                            label={`Upload document here *`}
                          />
                          <span className="error ">
                            {
                              //@ts-ignore
                              errors?.boq_details?.boqFinancialDoc?.[item]
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {bidDetail?.notes && (
              <div className="notes-content">
                <label>Notes</label>
                <p
                  className={`${
                    showFullNotes ||
                    !checkStringExceed(bidDetail.notes as string)
                      ? ""
                      : "note-box"
                  } `}
                  dangerouslySetInnerHTML={{
                    __html: bidDetail.notes as string,
                  }}
                ></p>
                {checkStringExceed(bidDetail.notes as string) && (
                  <span
                    className="view-more-button"
                    onClick={() => toggleNotes()}
                  >
                    {showFullNotes ? "View Less" : "View More"}
                  </span>
                )}
              </div>
            )}

            {bidDetail?.terms && (
              <div className="notes-content">
                <label>Terms & Conditions</label>
                <p
                  className={`${
                    showFullTerms ||
                    !checkStringExceed(bidDetail.terms as string)
                      ? ""
                      : "note-box"
                  } `}
                  dangerouslySetInnerHTML={{
                    __html: bidDetail?.terms as string,
                  }}
                ></p>
                {checkStringExceed(bidDetail.terms as string) && (
                  <span
                    className="view-more-button"
                    onClick={() => toggleTerms()}
                  >
                    {showFullTerms ? "View Less" : "View More"}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="position">
          <button
            className="save-btn float-left"
            onClick={() => {
              setPage(BidDetailTabs.BID_FEE);
            }}
          >
            Back
          </button>
          <div className="save-section">
            <button
              type="submit"
              className="transparent-btn "
              disabled={isSubmitting || Object.keys(errors).length > 0}
            >
              {isSubmitting ? (
                <CustomLoader
                  type="TailSpin"
                  color="#0052a7"
                  width={88}
                  height={20}
                />
              ) : (
                <span>Save</span>
              )}
            </button>
            <button
              onClick={() => {
                //validate the data and if validation pass the modal open
                onHandleSubmit(
                  values,
                  documentNeed as string,
                  catalogueFiles as string,
                  boqFinancialFiles as string,
                  boqFinancialResDocs as IBoqCatalogueFiles
                );
              }}
              className="save-btn btn-edit"
              type="button"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
