import { ErrorMessage, Field, FieldProps } from "formik";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { NepaliDistricts } from "../../Datas/Districts";
import {
  getDateDifferenceInDays,
  getDateDifferenceInDaysDate,
} from "../../utils/services";
import { getTenderAttrs } from "../Api/GetApi";
import { useENoticeFormik } from "../FormikWrapper/ENoticeFormikWrapper";
import { CustomLoader } from "../Loader/Loader";
import { NoteComponent } from "../NoteComponent/NoteComponent";
import { ConfirmationPopUp } from "../PopUp/ConfirmationPopUp";
import CustomSelect, { Option } from "../SelectComponent/SelectComponent";
import { addDaysInDate } from "../utils/generic";
import "./NewENotice.scss";
import { CustomCkEditor } from "../customCkEditor/customCkEditor";
import { DatePicker } from "rsuite";
import moment from "moment";
import { FormikCustomDatePicker } from "../CustomDatePicker/FormikCustomDatePicker";
interface Props {
  loading?: any;
  publishLoad?: boolean;
}

export const NewENotice: React.FC<Props> = ({ loading, publishLoad }) => {
  const { values, setFieldValue, errors, handleSubmit, validateForm } = useENoticeFormik();
  const [noticeCat, setNoticeCat] = useState<Option[]>([]);
  const [projectCat, setProjectCat] = useState<Option[]>([]);
  const [minSubmissionDate, setMinSubmissionDate] = useState<Date | null>(null);
  const [minPublishingDate, setMinPublishingDate] = useState<Date | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const fetchCategory = async () => {
    try {
      const res = await getTenderAttrs();
      if (res.status === 200) {
        setNoticeCat(res.data.data.notice_category);
        setProjectCat(res.data.data.project_category);

        if (values.notice_category?.value) {
          setFieldValue(
            "notice_category.label",
            res.data.data.notice_category.find(
              (each: Option) => each.value === values.notice_category?.value
            ).label
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const handleSubmissionDateUpdate = (
    publishingDate: Date,
    submissionDate: Date | null
  ) => {
    const differenceInDays = getDateDifferenceInDaysDate(
      publishingDate,
      submissionDate ?? new Date()
    );
    if (differenceInDays < 5) {
      const newSubmissionDate = addDaysInDate(publishingDate, 5);
      setMinSubmissionDate(newSubmissionDate);
      setFieldValue("submission_date", newSubmissionDate);
      return;
    }
    setMinSubmissionDate(publishingDate);
    setFieldValue("submission_date", submissionDate);
  };

  useEffect(() => {
    const submissionDate = addDaysInDate(values.publishing_date, 5);
    setMinSubmissionDate(submissionDate);
  }, [values.publishing_date]);

  useEffect(() => {
    const currentDate = new Date();

    const calculatedMinPublishingDate = currentDate;
    setMinPublishingDate(calculatedMinPublishingDate);
    setMinSubmissionDate(addDaysInDate(calculatedMinPublishingDate, 5));
  }, []);

  const customLoginStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "40%",
    },
  };

  function toggleModal() {
    setIsOpen(!isOpen);
  }


  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        style={customLoginStyles}
        ariaHideApp={false}
      >
        <ConfirmationPopUp
          popUpContents={{
            question1: "Are you sure want to publish notice?",
            // warn: "Your tender would be published",
            aprroveBtn: "Yes",
            cancelBtn: "Cancel",
          }}
          toggleModal={toggleModal}
          activeButtonLoading={publishLoad}
          onAprroveButton={() => {
            setFieldValue("saved", false);
            toggleModal();
            if (errors && Object.keys(errors).length > 0) {
              toast.error("You have got unresolved error");
              return;
            }
            handleSubmit();
          }}
          acceptTerm={true}
          publisherTerm={true}
        />
      </Modal>

      <div className="new-enotice">
        <div className="mb-3">
          <div className="outer-box">
            <div className="row">
              <div className="col-md-6">
                <label className="form-label">
                  Notice Title <span className="red">*</span>
                </label>
                <Field
                  placeholder="Enter Notice Title"
                  className="form-control"
                  name="title"
                />
                {errors.title && <span className="error">{errors.title}</span>}
              </div>

              <div className="col-md-6">
                <label className="form-label">
                  Notice Category<span className="red">*</span>
                </label>
                <Field
                  className=""
                  value={values.notice_category}
                  options={noticeCat}
                  component={CustomSelect}
                  placeholder="Select notice category"
                  isMulti={false}
                  onChange={(selected: Option) => {
                    setFieldValue("notice_category", selected);
                  }}
                />
                {errors.notice_category && (
                  <span className="error">{errors.notice_category}</span>
                )}
              </div>

              <div className="col-md-6">
                <label className="form-label">
                  Project Type<span className="red">*</span>
                </label>
                <Field
                  className=""
                  name="project_category"
                  options={projectCat}
                  component={CustomSelect}
                  placeholder="Select Project Category"
                  isMulti={true}
                />

                {errors.project_category && (
                  <span className="error">{errors.project_category}</span>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  Project Location<span className="red">*</span>
                </label>
                <Field
                  className=""
                  name="work_locations"
                  options={NepaliDistricts}
                  component={CustomSelect}
                  placeholder="Select work location"
                  isMulti={true}
                />
                {errors.work_locations && (
                  <span className="error">{errors.work_locations}</span>
                )}
              </div>

              <div className="col-md-6">
                <label className="form-label">
                  Publishing Date <span className="red">*</span>
                </label>
                <Field name="publishing_date" style={{ marginRight: '4px' }} >
                  {({ field, form }: FieldProps) => (
                    <DatePicker
                      {...field}
                      format="dd/MM/yyyy"
                      style={{ width: 220 }}
                      value={field.value ? new Date(field.value) : null}
                      onChange={(date) => {
                        let formatedDate = moment(date).format("YYYY-MM-DD");
                        form.setFieldValue(field.name, formatedDate);
                        handleSubmissionDateUpdate(
                          date ? new Date(date) : new Date(),
                          values?.submission_date
                        );
                      }}
                      disabledDate={(date) =>
                        date && minPublishingDate
                          ? date < minPublishingDate
                          : false
                      }
                    />
                  )}
                </Field>
                <span className="error">
                  <ErrorMessage name="publishing_date" />
                </span>
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  Last Submission Date /Deadline <span className="red">*</span>
                </label>
                <FormikCustomDatePicker
                  minSubmissionDate={minSubmissionDate}
                  name="submission_date"
                />
              </div>
              <div className="col-md-12">
                <label className="form-label">Description</label>
                <CustomCkEditor
                  dataValue={values.notes}
                  setFieldValue={(data: string) => {
                    setFieldValue("notes", data);
                  }}
                />
                {errors.notes && <span className="error">{errors.notes}</span>}
              </div>
            </div>
            <div className="line"></div>

            <div className="col-md-12 right">
              <button
                className="btn transparent-btn btn-edit mr-2"
                type="submit"
                onClick={() => {
                  setFieldValue("saved", true);
                }}
              >
                {loading ? (
                  <CustomLoader
                    type="TailSpin"
                    color="#FFFFFF"
                    height={22}
                    width={33}
                  />
                ) : (
                  <span>Save</span>
                )}
              </button>
              <button
                type="button"
                disabled={loading || publishLoad}
                className="btn btn-edit primary-btn"
                onClick={() => {
                  validateForm().then((errors) => {
                    if (errors && Object.keys(errors).length > 0) {
                      toast.error(
                        "Fill in all required fields with valid details"
                      );
                      return;
                    }
                    toggleModal();
                  });
                }}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
