import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { AdShow } from "../../Components/AdShow/AdShow";
import {
  getAllTenderNotice,
  getNotification,
} from "../../Components/Api/GetApi";
import { useAuth } from "../../Components/Context/AuthContext";
import { useSearch } from "../../Components/Context/SearchContext";
import { EBidPage } from "../../Components/EBids/EBids";
import { ENoticePage } from "../../Components/ENotice/ENotice";
import { ETenderSide } from "../../Components/ETenderSide/ETenderSide";
import { GalleryView } from "../../Components/GalleryView/GalleryView";
import { ItWorks } from "../../Components/ItWorks/ItWorks";
import NewBanner from "../../Components/NewBanner/NewBanner";
import { OurClients } from "../../Components/OurClients/OurClients";
import {
  CardView,
  TenderCardGroup,
} from "../../Components/TenderCard/TenderCardGroup";
import { Testimonials } from "../../Components/Testimonials/Testimonails";
import { catchError } from "../../Components/utils/catchError";
import "./HomePage.scss";
import Modal from "react-modal";
import EditCompanyDetails from "../../Components/EditDetails/EditCompanyDetails";
import { shouldShowEditCompanyPopup } from "../../utils/services";
import { NoticeType } from "../../utils/EnumConstants";

const customClass = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "600px",
    width: "60%",
    zIndex: 50,
  },
};

const allBidSelectOptions = [
  {
    label: "List",
    value: "list",
  },
  {
    label: "Gallery",
    value: "image",
  },
  {
    label: "Table",
    value: "table",
  },
];

const selectOptions = [
  {
    label: "List",
    value: "list",
  },
  {
    label: "Table",
    value: "table",
  },
];

export const HomePage = () => {
  const { isAuthenticate, eligible, userData } = useAuth();
  const [showButton, setShowButton] = useState(false);
  const [viewType, setViewType] = useState("list");
  const [allTenderLoading, setAllTenderLoading] = useState<boolean>(true);
  const [eNoticeTenderLoading, setENoticeTenderLoading] =
    useState<boolean>(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [ebidCount, setEbidCount] = useState(0);
  const [eNoticeCount, setENoticeCount] = useState(0);
  const [showEbidsCount, setShowEBidsCount] = useState(true);
  const [showEnoticeCount, setShowENoticeCount] = useState(true);

  const [eBidloading, setEBidLoading] = useState<Boolean>(true);
  const [eBidTenderLoading, setEBidTenderLoading] = useState<boolean>(true);
  const [eNoticeloading, setENoticeLoading] = useState<Boolean>(true);
  const { query, page, setPage } = useSearch();
  const [eBidTenderCard, setEBidTenderCard] = useState<any>();
  const [allBidTenderCard, setAllBidTenderCard] = useState<any>();
  const [eNoticeTenderCard, setENoticeTenderCard] = useState<any>();
  const [eBidTenderSideCard, setEBidTenderSideCard] = useState<any>();
  const [eNoticeTenderSideCard, setENoticeTenderSideCard] = useState<any>();
  const [totalAllTender, setTotalAllTender] = useState<number>(1);
  const [allPageSize, setAllPageSize] = useState<number>(0);
  const [totalEbidTender, setTotalEbidTender] = useState<number>(1);
  const [ebidPageSize, setEbidPageSize] = useState<number>(0);
  const [totalNoticeTender, setTotalNoticeTender] = useState<number>(1);
  const [noticePageSize, setNoticePageSize] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const [currentOptions, setCurrentOptions] = useState(selectOptions);
  const [isCDOpen, setIsCDOpen] = useState(false);

  const { showCompanyPopup } = shouldShowEditCompanyPopup(
    isAuthenticate,
    eligible,
    userData?.email
  );

  const toggleCDModal = () => {
    setIsCDOpen(!isCDOpen);
  };

  useEffect(() => {
    if (showCompanyPopup) {
      toggleCDModal();
    }
  }, [showCompanyPopup]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200 && !showButton) {
        setShowButton(true);
        return;
      }
      if (window.scrollY === 0) {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (tabIndex === 0) {
      setCurrentOptions(allBidSelectOptions);
      setViewType("list");
    } else {
      setCurrentOptions(selectOptions);
      setViewType("list");
    }
  }, [tabIndex]);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getView = (value: any) => {
    setViewType(value);
  };

  const today = new Date();
  const currentDate = today.toLocaleDateString("en-CA");
  const temp = localStorage.getItem("notification");

  const onEbidClick = () => {
    const tempNotification = temp ? JSON?.parse(temp as string) : {};

    const notification = {
      ...tempNotification,
      eBIdDate: currentDate,
      ebidCount: ebidCount,
    };
    if (tempNotification?.eBIdDate !== currentDate || !tempNotification) {
      return localStorage.setItem(
        "notification",
        JSON?.stringify(notification)
      );
    } else {
      return temp;
    }
  };

  const onENoticeClick = () => {
    const tempNotification = temp ? JSON?.parse(temp as string) : {};

    const notification = {
      ...tempNotification,
      eNoticeDate: currentDate,
      eNoticeCount: eNoticeCount,
    };
    if (tempNotification?.eNoticeDate !== currentDate || !tempNotification) {
      return localStorage.setItem(
        "notification",
        JSON?.stringify(notification)
      );
    } else {
      return temp;
    }
  };

  useEffect(() => {
    const tempNotification = temp ? JSON?.parse(temp as string) : {};
    if (tempNotification?.eNoticeDate === currentDate || eNoticeCount <= 0) {
      setShowENoticeCount(false);
    } else {
      setShowENoticeCount(true);
    }

    if (tempNotification?.eBIdDate === currentDate || ebidCount <= 0) {
      setShowEBidsCount(false);
    } else {
      setShowEBidsCount(true);
    }
  }, [tabIndex, eNoticeCount, ebidCount]);

  useEffect(() => {
    getNotification().then((res) => {
      setEbidCount(res?.data?.data?.["e-bids"]);
      setENoticeCount(res?.data?.data?.["e-notice"]);
      setEbidCount(res?.data?.data?.["e-bids"]);
      setENoticeCount(res?.data?.data?.["e-notice"]);
    });
  }, []);

  const handleEBidsViewAll = (): void => {
    onEbidClick();
    if (setTabIndex) {
      setTabIndex(1);
    }
  };

  const handleENoticeViewAll = (): void => {
    onENoticeClick();
    if (setTabIndex) {
      setTabIndex(2);
    }
  };

  const getTenders = (page: number) => {
    setAllTenderLoading(true);
    return getAllTenderNotice(page, query, false)
      .then((res) => {
        setAllBidTenderCard(res.data.data.results);
        setTotalAllTender(res.data.data.total);
        setAllPageSize(res.data.data.page_size);
      })
      .catch((err) => {
        catchError(
          err?.response?.status,
          err?.response?.data.status.status_message
        );
      })
      .finally(() => {
        setAllTenderLoading(false);
        setEBidLoading(false);
      });
  };

  const getEbidTenders = (page: number) => {
    setEBidTenderLoading(true);
    return getAllTenderNotice(page, query, true)
      .then((res) => {
        setEBidTenderCard(res.data.data.results);
        setTotalEbidTender(res.data.data.total);
        setEbidPageSize(res.data.data.page_size);
        if (page === 1) {
          const temp = res.data.data.results?.flatMap((item: any) => item.data);
          setEBidTenderSideCard(temp);
        }
      })
      .catch((err) => {
        catchError(
          err?.response?.status,
          err?.response?.data.status.status_message
        );
      })
      .finally(() => {
        setEBidTenderLoading(false);
        setEBidLoading(false);
      });
  };

  const combinedEBidData = useMemo(() => {
    return eBidTenderCard?.flatMap((item: any) => item.data);
  }, [eBidTenderCard]);

  const combinedENoticeData = useMemo(() => {
    return eNoticeTenderCard?.flatMap((item: any) => item.data);
  }, [eNoticeTenderCard]);

  const getENotice = (page: number, notice_type: string) => {
    setENoticeTenderLoading(true);
    return getAllTenderNotice(page, query, true, notice_type)
      .then((res) => {
        setENoticeTenderCard(res.data.data.results);
        setTotalNoticeTender(res.data.data.total);
        setNoticePageSize(res.data.data.page_size);
        if (page === 1) {
          const temp = res.data.data.results?.flatMap((item: any) => item.data);
          setENoticeTenderSideCard(temp);
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        setENoticeLoading(false);
        setENoticeTenderLoading(false);
      });
  };

  useEffect(() => {
    if (tabIndex === 0) {
      getTenders(page);
    } else if (tabIndex === 1) {
      getEbidTenders(page);
    } else if (tabIndex === 2) {
      getENotice(page, "E-Notice");
    }
  }, [page, tabIndex, query, isAuthenticate]);

  useEffect(() => {
    setEBidLoading(true);
    setENoticeLoading(true);
    getEbidTenders(1);
    getENotice(1, "E-Notice");
  }, []);

  useEffect(() => {
    const tabValue = searchParams.get("tab");
    if (tabValue) {
      setTabIndex(Number(tabValue));
    }
  }, [searchParams]);

  const handleLaterClick = () => {
    const companyPopupData = localStorage.getItem("loginDataArray");

    if (companyPopupData) {
      let parsedData = JSON.parse(companyPopupData);

      parsedData = parsedData.filter(
        (data: { firstLoginEmail: string }) =>
          data.firstLoginEmail !== userData?.email
      );

      localStorage.setItem("loginDataArray", JSON.stringify(parsedData));
    }
    toggleCDModal?.();
  };

  return (
    <div className="home-page">
      {showButton && (
        <img
          className={`scroll-top-btn`}
          src={require("../../images/scroll-top.svg").default}
          alt="scroll-to-top"
          onClick={handleClick}
        />
      )}
      {showCompanyPopup && (
        <Modal
          isOpen={isCDOpen}
          onRequestClose={() => {
            if (!eligible) {
              handleLaterClick();
            } else {
              toggleCDModal();
            }
          }}
          contentLabel="My dialog"
          style={customClass}
          ariaHideApp={false}
        >
          <EditCompanyDetails
            toggleModal={() => toggleCDModal()}
            handleLaterClick={handleLaterClick}
          />
        </Modal>
      )}
      <div className="home-page">
        {showButton && (
          <img
            className={`scroll-top-btn`}
            src={require("../../images/scroll-top.svg").default}
            alt="scroll-to-top"
            onClick={handleClick}
          />
        )}
        <NewBanner />
        <div className="card-wrapper-content">
          <div className="container">
            <div className="row">
              <div className="col-md-9 ">
                <div className="mg-top-bid"></div>
                <div className="main-tab">
                  <div className="switch-btns">
                    <GalleryView
                      getView={getView}
                      selectOptions={currentOptions}
                      viewType={viewType as CardView}
                    />
                  </div>

                  <Tabs
                    selectedIndex={tabIndex}
                    onSelect={(index) => {
                      setTabIndex(index);
                      setPage(1);
                    }}
                  >
                    <TabList>
                      <Tab>All Bids </Tab>
                      <Tab>
                        <div
                          className="tab-head"
                          onClick={() => {
                            onEbidClick();
                          }}
                        >
                          <div>
                            <span style={{ color: "#b61e25" }}>E</span>-Bids
                          </div>
                          {showEbidsCount && (
                            <div className="count">{ebidCount}</div>
                          )}
                        </div>
                      </Tab>
                      <Tab>
                        <div
                          className="tab-head"
                          onClick={() => {
                            onENoticeClick();
                          }}
                        >
                          <div>
                            <span style={{ color: "#b61e25" }}>E</span>-Notice
                          </div>
                          {showEnoticeCount && (
                            <div className="count">{eNoticeCount}</div>
                          )}
                        </div>
                      </Tab>
                    </TabList>
                    <TabPanel>
                      <TenderCardGroup
                        tenderCard={allBidTenderCard}
                        viewType={viewType as CardView}
                        loading={allTenderLoading}
                        totalTender={totalAllTender}
                        pageSize={allPageSize}
                      />
                    </TabPanel>
                    <TabPanel>
                      <EBidPage
                        viewType={viewType as CardView}
                        tenderCard={eBidTenderCard}
                        totalTender={totalEbidTender}
                        pageSize={ebidPageSize}
                        loading={eBidTenderLoading}
                      />
                    </TabPanel>
                    <TabPanel>
                      <ENoticePage
                        viewType={viewType as CardView}
                        totalTender={totalNoticeTender}
                        pageSize={noticePageSize}
                        tenderCard={eNoticeTenderCard}
                        loading={eNoticeTenderLoading}
                      />
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
              <div className="col-md-3">
                <div className="mg-top-bid"></div>
                <div className="home-sidebar">
                  <div className="mb-4">
                    <ETenderSide
                      loading={eBidloading}
                      handleViewAll={handleEBidsViewAll}
                      sectionTitle={"E-bids"}
                      tenderCard={combinedEBidData}
                      theme="Secondary"
                    />
                  </div>
                  <div className="mb-4">
                    <ETenderSide
                      loading={eNoticeloading}
                      handleViewAll={handleENoticeViewAll}
                      sectionTitle={"E-Notices"}
                      tenderCard={combinedENoticeData}
                      noticeType={NoticeType.ENOTICE}
                      theme="Primary"
                    />
                  </div>
                  <div className="sidebar-sticky">
                    <AdShow />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ItWorks />
        <Testimonials />
        <OurClients />
      </div>
    </div>
  );
};
