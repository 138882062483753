import { mdiClose, mdiInformationOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import "../../Components/RejectPopup/RejectPopup.scss";
export interface Props {
  toggleModal?: () => void;
  tender_notice_id?: string | number;
  bidder_id?: string | number;
  onReject?: any;
  isNotAwardedDialog?: boolean;
}
export interface TenderRejectInitial {
  tender_notice_id: string | number;
  bidder_id: string | number;
  rejection_reason?: string;
  not_awarded_reason?: string;
}

export const NewRejectPopup: React.FC<Props> = ({
  toggleModal,
  tender_notice_id,
  bidder_id,
  onReject,
  isNotAwardedDialog
}) => {

  const validate = Yup.object({
    rejection_reason: Yup.string().optional()
      .max(60, "Only 60 characters are allowed.")
      .test('reason', 'Required disqualify reason', value => {
        return isNotAwardedDialog ? true : Boolean(value);
      }
      ),
    not_awarded_reason: Yup.string().optional().max(60, "Only 60 characters are allowed.").optional
      ().test('reason', 'Required not-awarded reason', value => {
        return isNotAwardedDialog ? Boolean(value) : true;
      }
      ),
  });
  return (
    <Formik
      initialValues={{
        tender_notice_id: tender_notice_id,
        bidder_id: bidder_id,
        rejection_reason: undefined,
        not_awarded_reason: undefined,
      }}
      onSubmit={(values) => {
        if (isNotAwardedDialog) {
          delete values.rejection_reason;
        }
        else {
          delete values.not_awarded_reason
        }
        onReject(values);
      }}
      validationSchema={validate}
    >
      <Form>
        <div className="reject-bid ">
          <div className="container">
            <div className="overlay"></div>
            <div className="close-icon" onClick={toggleModal}>
              <Icon className=" mdi-close" path={mdiClose} />
            </div>
            <div className="info-logo">
              <Icon className="mdi-alert" path={mdiInformationOutline}></Icon>
            </div>
            <div>
              <h5 className="text-title">{isNotAwardedDialog ? "Mark as Not Awarded!" : "Disqualify Bid!"} </h5>
              <p className="sub-header">
                Are you sure you want to {isNotAwardedDialog ? "mark this bidder as Not Awarded for this" : "disqualify"} bid?
              </p>
            </div>

            <div className="popup-body">
              <label className="form-label">{isNotAwardedDialog ? "Not Awarded" : "Disqualify"} Reason:</label>
              <Field
                as="textarea"
                name={isNotAwardedDialog ? "not_awarded_reason" : "rejection_reason"}
                className="form-control"
                aria-describedby="emailHelp"
              />
              <span className="error">
                <ErrorMessage name={isNotAwardedDialog ? "not_awarded_reason" : "rejection_reason"} />
              </span>
            </div>

            <div className="popup-btn">
              <div>
                <button
                  className="btn-cancel"
                  onClick={toggleModal}
                  type="button"
                >
                  Cancel
                </button>
              </div>
              <div>
                <button className="btn-withdraw" type="submit">
                  Yes, {isNotAwardedDialog ? "Mark Not Awarded" : "disqualify bid"} 
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
