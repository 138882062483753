import { ErrorMessage, Field, FieldProps } from "formik";
import { DatePicker } from "rsuite";

interface CustomDatePickerProps {
  minSubmissionDate: Date | null;
  name: string;
}
export const FormikCustomDatePicker = ({ minSubmissionDate, name }: CustomDatePickerProps) => {
  return (
    <div>
      <div className="d-flex justify-content-start">
        <div className=" mr-3">
          <Field name={name}>
            {({ field, form }: FieldProps) => (
              <DatePicker
                {...field}
                format="dd/MM/yyyy"
                showMeridian
                style={{ width: 220 }}
                value={field.value ? new Date(field.value) : new Date()}
                onChange={(date) => form.setFieldValue(field.name, date)}
                disabledDate={(date) =>
                  date && minSubmissionDate ? date < minSubmissionDate : false
                }
              />
            )}
          </Field>
        </div>
        <div>
          <Field name={name}>
            {({ field, form }: FieldProps) => (
              <DatePicker
                {...field}
                format="hh:mm aa"
                showMeridian
                style={{ width: 220 }}
                value={field.value ? new Date(field.value) : new Date()}
                onChange={(date) => form.setFieldValue(field.name, date)}
              />
            )}
          </Field>
        </div>
      </div>

      <span className="error">
        <ErrorMessage name={name} />
      </span>
    </div>
  );
};
