import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { register, sendRegisterOTP } from "../Api/PostApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { catchError } from "../utils/catchError";
import { Role } from "../../utils/EnumConstants";

export const initialvalues = {
  role: Role.Bidder,
  sign_up_as: "",
  first_name: "",
  last_name: "",
  middle_name: "",
  password: "",
  email: "",
  contact_no: "",
  country_code: "",
  logo: null,
  introduction: null,
  estd: null,
  employee_no: null,
  skip: "false",
  otp: "",
};

export const validationSchema1 = Yup.object({
  role: Yup.string().required("Required"),
  sign_up_as: Yup.string().when("role", {
    is: (role: string) => role === Role.Bidder,
    then: Yup.string().required("Required"),
  }),
  first_name: Yup.string()
    .min(2, "Name too small")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces")
    .required("Required"),
  middle_name: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces")
    .min(2, "Name too small"),
  // address: Yup.string().required('Required'),
  last_name: Yup.string()
    .min(2, "Name too small")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces")
    .required("Required"),
  contact_no: Yup.string()
    .required("Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .min(11, "Phone number is not valid.")
    .max(13, "Phone number is not valid."),

  email: Yup.string().email("Invalid email address").required("Required"),

  password: Yup.string()
    .min(8, "More than 8 characters or more")
    .required("Required"),
  confirmpassword: Yup.string()
    .required("Required")
    .when("password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password does not match"
      ),
    }),
});

export const validationSchema2 = Yup.object({
  otp: Yup.string()
    .min(4, "More than 4 characters or more")
    .required("Required"),
});

const formData = new FormData();

export interface FormikWrapperProps {
  children: any;
  page: number;
  setPage: (page: number) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  skip: boolean;
  setSkipLoading: (skipLoading: boolean) => void;
  skipLoading: boolean;
  handleSendOtp: (email: string) => void;
}

const RegisterFormikWrapper: React.FC<FormikWrapperProps> = ({
  children,
  page,
  setLoading,
  setSkipLoading,
  skip,
  handleSendOtp
}) => {
  const navigate = useNavigate();

  const saveLoginDataToLocalStorage = (email: string) => {
    const existingData = JSON.parse(localStorage.getItem("loginDataArray") || "[]");

    const newLoginData = {
      showCompanyPopup: "true",
      firstLoginEmail: email,
    };

    existingData.push(newLoginData);

    localStorage.setItem("loginDataArray", JSON.stringify(existingData));
  };

  const registerUser = (formData: any, setSubmitting: any, resetForm: any, email: string) => {
    if (skip === true) {
      setSkipLoading(true);
    } else {
      setLoading(true);
    }
    register(formData)
      .then((res) => {
        if (res.status === 200) {
          resetForm();
          saveLoginDataToLocalStorage(email);

          if (res.data?.data?.role === Role.Bidder) {
            toast.success("User Registered Successfully.");
            navigate(`/interest/${res.data?.data?.user_id}`);
          } else {
            toast.success(res.data.status.status_message);
            navigate("/");
          }
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        if (skip === true) {
          setSkipLoading(false);
        } else {
          setLoading(false);
        }
      });
  };



  return (
    <Formik
      initialValues={initialvalues}
      onSubmit={async (
        values: typeof initialvalues,
        { setSubmitting, resetForm }
      ) => {
        Object.entries(values).forEach(([key, value]) => {
          if (value !== null) {
            formData.append(key, value);
          }
        });

        if (page === 2) {
          registerUser(formData, setSubmitting, resetForm, values.email);
        } else {
          handleSendOtp(values.email);
        }
      }}
      validationSchema={page === 1 ? validationSchema1 : validationSchema2}
      validateOnChange={true}
      validateOnBlur={true}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default RegisterFormikWrapper;
