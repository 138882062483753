import {
  NewTenderCardProps,
  ResponseTenderCard,
} from "../TenderCard/TenderCardGroup";
import "./TenderTable.scss";
import SaveIcon from "@mui/icons-material/Save";
import GavelIcon from "@mui/icons-material/Gavel";
import { isBidder } from "../../utils/services";
import { useAuth } from "../Context/AuthContext";
import React from "react";
interface TenderTableProps {
  data: NewTenderCardProps;
  handleOnClickBid?: (tenderId: number) => void;
  handleOnClickSave?: (id: number) => void;
  handleOnClickTitle?: (data: ResponseTenderCard) => void;
  hasAction?: boolean;
  type: "bid" | "result";
}

export const TenderTable: React.FC<TenderTableProps> = ({
  data,
  handleOnClickBid,
  handleOnClickSave,
  handleOnClickTitle,
  hasAction = true,
  type,
}) => {
  const { role, isAuthenticate } = useAuth();

  const getBackgroundColor = (notice_category: string) => {
    switch (notice_category) {
      case "Tender/Quotation":
        return "primary";
      case "Auction":
        return "secondary";
      case "Request for Proposal":
        return "tertiary";
      case "Notice":
        return "quaternary";
      case "EOI":
        return "quinary";
      case "Enlistment / Standing List":
        return "senary";
      default:
        return "primary";
    }
  };

  return (
    <div className="tender-table">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>SN</th>
            <th>Title</th>
            <th>Company Name</th>
            <th>Notice / Award</th>
            <th>Project Category</th>
            <th>Published Date</th>
            {hasAction && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((item, index) => {
            return (
              <tr className="table-row" key={index}>
                <td width="3%">{index + 1}</td>
                <td width="25%">
                  <div
                    className="tender-title"
                    onClick={() => {
                      handleOnClickTitle?.(item as ResponseTenderCard);
                    }}
                    title="Click to view"
                  >
                    {item?.title}
                  </div>
                </td>
                <td width="15%">{item?.public_entity_name}</td>
                <td width="10%">
                  {" "}
                  {type === "bid" ? (
                    <div className={getBackgroundColor(item?.notice_category)}>
                      {item?.notice_category}
                    </div>
                  ) : (
                    <>
                      {item?.award_result?.map(
                        (award: any, awardIndex: number) => {
                          return (
                            <React.Fragment key={awardIndex}>
                              {award?.company}
                              {awardIndex !== item?.award_result?.length - 1 &&
                                ", "}
                            </React.Fragment>
                          );
                        }
                      )}
                    </>
                  )}
                </td>
                <td width="15%">
                  {item?.project_category?.map(
                    (project: any, projectIndex: number) => {
                      return (
                        <div className="category-tag" key={projectIndex}>
                          {project?.label}
                        </div>
                      );
                    }
                  )}
                </td>
                <td width="5%">
                  {item?.publishing_date} <br />{" "}
                  {item?.remaining_days === 0 ? (
                    <span className="danger-text">(Expired)</span>
                  ) : (
                    <span className="danger-text">
                      ({item?.remaining_days} days remaining)
                    </span>
                  )}
                </td>
                {hasAction && (
                  <td width="5%">
                    {(!isAuthenticate || isBidder(role)) && (
                      <div className="action-icon-block">
                        {item?.private &&
                          Number(item?.remaining_days) > 0 &&
                          item?.specification_terms_format && (
                            <div
                              className="action-icon"
                              onClick={() => {
                                handleOnClickBid?.(item?.id as number);
                              }}
                            >
                              <GavelIcon />
                            </div>
                          )}

                        {(!item?.private ||
                          !item?.specification_terms_format) && (
                          <div
                            className="action-icon"
                            onClick={() => {
                              handleOnClickSave?.(item?.id);
                            }}
                          >
                            <SaveIcon />
                          </div>
                        )}
                      </div>
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
