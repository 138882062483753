import { Pagination } from "@mui/material";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { ResultShimmer } from "../../Pages/Results/ResultShimmer";
import { isBidder } from "../../utils/services";
import { tenderBid } from "../Api/PostApi";
import { useAuth } from "../Context/AuthContext";
import { useGlobal } from "../Context/GlobalContext";
import { ModalContext } from "../Context/ModalContext/ModalContext";
import { useSearch } from "../Context/SearchContext";
import { NoDataFound } from "../NoDataFound/NoDataFound";
import usePagination from "../Pagination/usePagination";
import { TenderCard } from "../TenderCard/TenderCard";
import "../TenderCard/TenderCard.scss";
import { TenderCardAd } from "../TenderCard/TenderCardAd";
import { CardView, ResponseTenderCard } from "../TenderCard/TenderCardGroup";
import { TenderTable } from "../TenderTable/TenderTable";
import "../TenderCard/TenderCard.scss";
import { checkDay } from "../utils/DateConversion";
import { catchError } from "../utils/catchError";

export interface NewTenderCardProps {
  date?: string;
  data?: Array<ResponseTenderCard>;
}

export interface ENoticePageProps {
  tenderCard?: any;
  loading: boolean;
  totalTender: number;
  pageSize: number;
  viewType: CardView;
}

export const ENoticePage: React.FC<ENoticePageProps> = ({
  tenderCard,
  totalTender,
  pageSize,
  loading,
  viewType
}) => {
  const { advertisements } = useGlobal();
  const { page, setPage } = useSearch();
  const [ignore, setIgnore] = useState<boolean>(false);
  const { isAuthenticate, role, userData, eligible } = useAuth();
  const { TogglePopUpFlags } = useContext(ModalContext);
  var adIndex = 0;
  // This function is made to get all the tenders


  const handleChange = (e: any, p: any) => {
    window.scrollTo({ top: 0 });
    setPage(p);
    _DATA.jump(p);
  };


  const count: number = Math.ceil(Number(totalTender) / Number(pageSize));
  const _DATA = usePagination(tenderCard || [], pageSize);

  const FirstBidCheck = (tender_id: string | number | undefined) => {
    const data = {
      tender_notice_id: tender_id,
    };
    tenderBid(data)
      .then((res) => {
        if (res.status === 202) {
          toast.error(res.data.status.status_message);
        }
        if (res.status === 200) {
          window.open(`/tender-bid/${tender_id}`, "_blank");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return TogglePopUpFlags("login");
        }
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };
  const handleOnClickBid = (id: number) => {
    if (isAuthenticate && isBidder(role)) {
      if (eligible) {
        FirstBidCheck(id);
      } else {
        TogglePopUpFlags("editCompanyDetail");
      }
    } else {
      TogglePopUpFlags("login");
    }
  };

  const navigateToNoticeDetail = (data: ResponseTenderCard) => {
    const shareUrl = `${data?.private ? `bids/view/${data?.id}` : `tender-notice/${data?.id}`
      }`;

    if (!data?.id) return;
    if (data?.private && !data?.specification_terms_format) {
      window.open(`/e-notice/view/${data?.id}`, "_blank");
    } else {
      window.open(shareUrl, "_blank");
    }
  };

  const handleOnClickTitle = (data: ResponseTenderCard) => {
    if (isAuthenticate || data?.private) {
      if (
        isBidder(role) &&
        userData?.plan_remaining_days === 0 &&
        !data?.private
      ) {
        var planUrl = `${window.location.origin}/pricing-plan`;
        window.open(planUrl, "_blank");
      } else {
        navigateToNoticeDetail(data);
      }
    } else {
      TogglePopUpFlags("login");
    }
  };

  return (
    <div className="cardwrapper-section">
      <div>
        {loading ? (
          <ResultShimmer />
        ) : (
          <>
            {tenderCard && tenderCard.length > 0 ? (
              tenderCard.map((item: NewTenderCardProps, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <h4 className="time-date">{checkDay(item.date)}</h4>
                    <div className="">
                      {viewType !== "table" ? (
                        <>
                          {(item.data as ResponseTenderCard[]).map(
                            (val: ResponseTenderCard, id: number) => {
                              return (
                                <>
                                  <React.Fragment key={id}>
                                    <TenderCard
                                      key={id}
                                      ignore={ignore}
                                      setIgnore={setIgnore}
                                      savebids={false}
                                      dataValue={val}
                                      handleOnClickBid={handleOnClickBid}
                                      handleOnClickTitle={handleOnClickTitle}
                                    />

                                    {page === 1 &&
                                      (id + 1) % 3 === 0 &&
                                      advertisements &&
                                      advertisements["In-Between-Notice"]?.[
                                      adIndex
                                      ] && (
                                        <div className="notice-in-between-card">
                                          <TenderCardAd
                                            advertisement={
                                              advertisements &&
                                              advertisements[
                                              "In-Between-Notice"
                                              ]?.[adIndex]
                                            }
                                          />
                                          <div style={{ display: "none" }}>
                                            {adIndex++}
                                          </div>
                                        </div>
                                      )}
                                  </React.Fragment>
                                </>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <TenderTable
                          data={item}
                          hasAction={isBidder(role)}
                          type="bid"
                          handleOnClickTitle={handleOnClickTitle}
                          handleOnClickBid={handleOnClickBid}
                        />
                      )}
                    </div>
                  </React.Fragment>
                );
              })
            ) : (
              <div>
                <NoDataFound
                  text1="No tenders found"
                  img={require("../../images/nodata.png")}
                />
              </div>
            )}
            {tenderCard && tenderCard.length > 0 && (
              <Pagination
                count={count}
                size="large"
                page={page}
                shape="rounded"
                onChange={handleChange}
                siblingCount={0}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
